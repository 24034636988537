import clsx from 'clsx';
import {Control, FieldValues, useController} from 'react-hook-form';
import * as styles from './scopes.module.css';
import Checkbox from '../../core/form/input/checkbox';

interface IScopesProps {
    control: Control<FieldValues>,
    name: string,
    id: string,
    group: string,
    standaloneScopes?: string[] | [],
}

const Scopes = ({
    control,
    name,
    group,
    id,
    standaloneScopes,
}: IScopesProps) => {
    const {
        field: {
            onChange,
            value,
        },
    } = useController({
        name: `${group}[${id}]`,
        control,
    });
    const hasBorder = Object.values(value || {}).includes(true);

    if (!value) {
        return null;
    }

    return (
        <div
            className={clsx(
                styles.container,
                hasBorder && styles.border,
            )}
        >
            <div className={styles.name}>{name}</div>
            <div className={styles.checkboxes}>
                {value.write !== undefined && (
                    <Checkbox
                        checked={value.write}
                        name={`${id}.write`}
                        label="Edit"
                        handleChange={() => {
                            const isChecked = value.write !== true;

                            const newState = {
                                ...value,
                                write: isChecked,
                            };

                            if (value.read !== undefined) {
                                newState.read = value.read ? value.read : isChecked;
                            }

                            if (value.basicInfo !== undefined) {
                                newState.basicInfo = isChecked ? value.basicInfo : false;
                            }

                            return onChange(newState);
                        }}
                    />
                )}
                {value.basicInfo !== undefined && (
                    <Checkbox
                        disabled={value.write !== true}
                        checked={value.basicInfo}
                        name={`${id}.basicInfo`}
                        label="Basic info"
                        handleChange={() => {
                            const isChecked = value.basicInfo !== true;

                            const newState = {
                                ...value,
                                basicInfo: isChecked,
                            };

                            return onChange(newState);
                        }}
                    />
                )}
                {value.read !== undefined && (
                    <Checkbox
                        checked={value.read}
                        name={`${id}.read`}
                        label="View"
                        handleChange={() => {
                            const isChecked = value.read !== true;

                            const newState = {
                                ...value,
                                read: isChecked,
                            };

                            if (value.write !== undefined) {
                                newState.write = value.write ? isChecked : value.write;
                            }

                            return onChange(newState);
                        }}
                    />
                )}
                {standaloneScopes && standaloneScopes.length > 0 && standaloneScopes.map(scope => (
                    <Checkbox
                        key={scope}
                        checked={value[scope]}
                        name={`${id}.${value[scope]}`}
                        label={scope.toUpperCase()}
                        handleChange={() => {
                            const isChecked = value[scope] !== true;

                            const newState = {
                                ...value,
                                [scope]: isChecked,
                            };

                            return onChange(newState);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Scopes;
